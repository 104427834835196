import { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { MasterLayout } from "../../_edumate/layout/MasterLayout";
import { Dashboard } from "../pages/dashboard/Dashboard";

const PrivateRoutes = () => {
  const ReportsPage = lazy(() => import("../modules/reports/ReportsPage"));
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<Dashboard />} />
        {/* Lazy Modules */}
        <Route
          path="reports/*"
          element={
            <SuspensedView>
              <ReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path="profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView = ({ children }) => {
  TopBarProgress.config({
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
