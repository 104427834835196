import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
import { toAbsoluteUrl } from "../../../helpers";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItemLink } from "./SidebarMenuItemLink";

const Sidebar = () => {

  const[isActive, setActive] = useState(false)

  const {
    currentUser: { groups },
  } = useAuth();

  const toogle = () => {
    setActive(!isActive);
  }

  return (
    <ul
      className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${isActive ? 'toggled' : ''}`}
      id="accordionSidebar"
    >
      {/* <!-- Sidebar - Brand --> */}
      <div className="sidebar-brand d-flex align-items-center justify-content-center">
        {/* <!-- Sidebar - Logo --> */}
        <Link to="/dashboard">
          <img
            alt="Logo"
            className="img-fluid p-1"
            style={{ 'WebkitFilter': 'invert(100%)' }}
            src={toAbsoluteUrl("/media/logos/logo.png")}
          />
        </Link>
        {/* <!-- End - Logo --> */}
      </div>
      {/* <!-- End - Brand --> */}

      {/* <!-- Divider --> */}
      <hr className="sidebar-divider my-0" />

      {/* <!-- Nav Item - Dashboard --> */}
      <SidebarMenuItem
        to="/dashboard"
        title="Dashboard"
        icon="tachometer-alt"
      />

      {/* <!-- Divider --> */}
      <hr className="sidebar-divider" />

      {/* <!-- Heading --> */}
      <div className="sidebar-heading">Reports</div>

      {/* <!-- Nav Item - Pages Collapse Menu --> */}
      <SidebarMenuItemWithSub
        to="#"
        title="Reports"
        icon="sheet-plastic"
        controls="reports"
      >
        <div
          id="reports"
          className={`collapse ${isActive ? 'hide' : ''}`}
          aria-labelledby="headingTwo"
          data-bs-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <SidebarMenuItemLink classes="collapse-item" to="/reports/new" title="New" />
            <SidebarMenuItemLink classes="collapse-item" to="/reports/overview" title="List" />
            {groups.includes('Admin') && (<SidebarMenuItemLink classes="collapse-item" to="/reports/clone" title="Clone" />)}
          </div>
        </div>
      </SidebarMenuItemWithSub>

      {groups.includes('Admin') && (<>
      
        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />

        {/* <!-- Heading --> */}
        <div className="sidebar-heading">Settings</div>

        {/* <!-- Nav Item - Organisations --> */}
        {/* <!-- Nav Item - Pages Collapse Menu --> */}
        <SidebarMenuItemWithSub
          to="#"
          title="Organisations"
          icon="fa-school"
          controls="organisations"
        >
          <div
            id="organisations"
            className={`collapse ${isActive ? 'hide' : ''}`}
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <SidebarMenuItemLink classes="collapse-item" to="/settings/organisations/new" title="New" />
              <SidebarMenuItemLink classes="collapse-item" to="/settings/organisations/overview" title="List" />
            </div>
          </div>
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub
          to="#"
          title="Users"
          icon="fa-user"
          controls="users"
        >
          <div
            id="users"
            className={`collapse ${isActive ? 'hide' : ''}`}
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <SidebarMenuItemLink classes="collapse-item" to="/settings/users/new" title="New" />
              <SidebarMenuItemLink classes="collapse-item" to="/settings/users/overview" title="List" />
            </div>
          </div>
        </SidebarMenuItemWithSub>

        {/* <!-- Nav Item - Settings --> */}
        {/* <SidebarMenuItem
          to="/settings/overview"
          title="Settings"
          icon="fa-gear"
        /> */}

      </>)}

      {/* <!-- Divider --> */}
      <hr className="sidebar-divider d-none d-md-block" />

      {/* <!-- Sidebar Toggler (Sidebar) --> */}
      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle" onClick={toogle} />
      </div>
    </ul>
  );
};

export { Sidebar };
