const config = {
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_BUCKET,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN
  },
  edumate: {
    EDUMATE_ORGANISATION_ID: process.env.REACT_APP_EDUMATE_ORGANISATION_ID,
  },
  MAX_ATTACHMENT_SIZE: 10000000
};

export default config;
