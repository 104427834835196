const keys = { 
  name: "MODULE_REPORT_NEW",
  description: "REPORT_DESCRIPTION",
  module: "MODULE_ID",
  category: "MODULE_REPORT_TYPE_ID",
  versionNumber: "VERSION"
}

const category = [
  "Widget Report",
  "SQL w/ template",
  "SQL report",
  "Correspondence",
  "Job"
]

const module = [
  "Attendance",
  "Academic",
  "Enrolment",
  "Accounts",
  "Timetable",
  "Exports",
  "General",
  "Foundation",
  "Welfare",
  "Curriculum"
]

const readingXml = async (xml, formik) => {

  console.log("XML", xml)

  for (const [key, value] of Object.entries(keys)) {
    switch (key) {
      case 'module':
        formik.setFieldValue(key - 1, module[xml.getElementsByTagName(value)[0].childNodes[0].nodeValue])
        break;
      case 'category':
        formik.setFieldValue(key - 1, category[xml.getElementsByTagName(value)[0].childNodes[0].nodeValue])
        break;
      default:
        formik.setFieldValue(key, xml.getElementsByTagName(value)[0].childNodes[0].nodeValue)
        break;
    }
  }
};

export { readingXml };
