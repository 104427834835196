import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../modules/auth";

const Dashboard = () => {
  const {
    currentUser: {
      user: { attributes },
      groups
    },
  } = useAuth();
  const [reportsCount, setReportsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const organisationId = attributes["custom:organisationId"];

  useEffect(() => {
    const onLoad = async () => {
      try {
        setLoading(true)
        const { reports } = await API.get(
          "edumate",
          `/organisations/${organisationId}/reports`
        );
        setReportsCount(reports.length || 0);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        throw new Error(e)
      }
    };
    if (!groups.includes("Admin")) onLoad();
  }, [organisationId]);

  return (
    <>
      {/* <!-- Reports Count --> */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
        <Link
          to="/reports/new"
          className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
        >
          <FontAwesomeIcon icon="add" fixedWidth className="me-1" />
          Create New Report
        </Link>
      </div>
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card border-left-primary shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  Reports (Count)
                </div>
                <div className="h5 mb-0 font-weight-bold text-gray-800">{reportsCount}</div>
              </div>
              <div className="col-auto">
                <FontAwesomeIcon
                  icon="file"
                  fixedWidth
                  size="2x"
                  className="text-gray-300"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Dashboard };
