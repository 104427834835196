import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../../app/modules/auth";
import { Avatar } from "../../../helpers";

const Topbar = () => {

  const { logout } = useAuth();

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      {/* <!-- Sidebar Toggle (Topbar) --> */}
      <button
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle mr-3"
      >
        <FontAwesomeIcon icon={["fa", "bars"]} fixedWidth />
      </button>

      {/* <!-- Topbar Search --> */}
      <form className="d-none d-sm-inline-block form-inline me-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div className="input-group">
          <input
            type="text"
            className="form-control bg-light border-0 small"
            placeholder="Search for..."
            aria-label="Search"
            aria-describedby="basic-addon2"
          />
          <button id="basic-addon2" className="btn btn-primary" type="button">
            <FontAwesomeIcon icon={"search"} fixedWidth />
          </button>
        </div>
      </form>

      {/* <!-- Topbar Navbar --> */}
      <ul className="navbar-nav ms-auto">
        {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
        <li className="nav-item dropdown no-arrow d-sm-none">
          <a
            className="nav-link dropdown-toggle"
            href="/#"
            id="searchDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <FontAwesomeIcon icon={"search"} fixedWidth />
          </a>
          {/* <!-- Dropdown - Messages --> */}
          <div
            className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
            aria-labelledby="searchDropdown"
          >
            <form className="form-inline mr-auto w-100 navbar-search">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="Search for..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button">
                    <FontAwesomeIcon icon={"search"} fixedWidth />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>

        {/* <!-- Nav Item - Alerts --> */}
        {/* <li className="nav-item dropdown no-arrow mx-1">
          <a
            className="nav-link dropdown-toggle"
            href="/#"
            id="alertsDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <FontAwesomeIcon icon={"bell"} fixedWidth size="lg" />
            <span className="badge bg-danger badge-counter">3+</span>
          </a>
          <div
            className="dropdown-list dropdown-menu dropdown-menu-end shadow animated--grow-in"
            aria-labelledby="alertsDropdown"
          >
            <h6 className="dropdown-header">Alerts Center</h6>
            <a className="dropdown-item d-flex align-items-center" href="/#">
              <div className="me-3">
                <div className="icon-circle bg-primary">
                  <FontAwesomeIcon icon={"file"} />
                </div>
              </div>
              <div>
                <div className="small text-gray-500">December 12, 2019</div>
                <span className="font-weight-bold">
                  A new monthly report is ready to download!
                </span>
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center" href="/#">
              <div className="me-3">
                <div className="icon-circle bg-success">
                <FontAwesomeIcon icon={"donate"} />
                </div>
              </div>
              <div>
                <div className="small text-gray-500">December 7, 2019</div>
                $290.29 has been deposited into your account!
              </div>
            </a>
            <a className="dropdown-item d-flex align-items-center" href="/#">
              <div className="me-3">
                <div className="icon-circle bg-warning">
                <FontAwesomeIcon icon={"exclamation-triangle"} />
                </div>
              </div>
              <div>
                <div className="small text-gray-500">December 2, 2019</div>
                Spending Alert: We've noticed unusually high spending for your
                account.
              </div>
            </a>
            <a
              className="dropdown-item text-center small text-gray-500"
              href="/#"
            >
              Show All Alerts
            </a>
          </div>
        </li>

        <div className="topbar-divider d-none d-sm-block"></div> */}

        {/* <!-- Nav Item - User Information --> */}
        <li className="nav-item dropdown no-arrow">
          <a
            className="nav-link dropdown-toggle"
            href="/#"
            id="userDropdown"
            data-bs-toggle="dropdown"
          >
            <Avatar />
          </a>
          {/* <!-- Dropdown - User Information --> */}
          <div
            className="dropdown-menu dropdown-menu-end shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            <Link className="dropdown-item" to="/profile/overview">
              <FontAwesomeIcon className="me-2 text-gray-400" icon="fa-user" fixedWidth />
              <span>Profile</span>
            </Link>
            {/* <a className="dropdown-item" href="/profile/overview">
              <i className="fas fa-user fa-sm fa-fw me-2 text-gray-400"></i>
              Profile
            </a> */}
            {/* {groups.includes('Admin') && (
            <a className="dropdown-item" href="/settings/overview">
              <i className="fas fa-cogs fa-sm fa-fw me-2 text-gray-400"></i>
              Settings
            </a>)} */}
            <div className="dropdown-divider"></div>
            <button className="dropdown-item" onClick={logout}>
              <i className="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400"></i>
              Logout
            </button>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export { Topbar };
