import { useEffect, useState } from "react";
import { API } from "aws-amplify";

const Reports = ({ formik, organisationId, label }) => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const onLoadReports = async () => {
      setLoading(true);
      try {
        const { reports } = await API.get(
          "edumate",
          `/organisations/${organisationId}/reports`
        );
        setReports(reports);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
    if (organisationId) onLoadReports();
    formik.resetForm({ values: { ...formik.values, report: "" } });
    // eslint-disable-next-line
  }, [organisationId]);

  return (
    <div className="col-md-12 mb-3">
      <label className="form-label fw-bolder text-dark fs-6">{label}</label>
      <select
        {...formik.getFieldProps("report")}
        className={`
                        form-select form-select-solid
                        ${
                          formik.touched.report &&
                          formik.errors.report &&
                          "is-invalid"
                        }
                        ${
                          formik.touched.report &&
                          !formik.errors.report &&
                          "is-valid"
                        }
                      `}
      >
        {loading && <option>Loading...</option>}
        {!loading && (
          <>
            <option value="">Choose...</option>
            {reports &&
              reports.map((report) => (
                <option key={report.reportId} value={report.reportId}>
                  {report.name}
                </option>
              ))}
          </>
        )}
      </select>
      {formik.touched.report && formik.errors.report && (
        <div className="invalid-feedback">{formik.errors.report}</div>
      )}
    </div>
  );
};
export { Reports };
